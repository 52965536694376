var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"filter-board-mobile position-relative"},[_c('div',{staticClass:"filter-list d-flex vw-100 bg-white"},_vm._l((_vm.filters),function(filter){return _c('div',{key:filter.key,staticClass:"filter w-50 d-flex align-items-center justify-content-between cursor-pointer",class:{
          disabled: _vm.expiredDay <= 0,
          'is-selecting': filter.key === _vm.currentSelecting,
        },on:{"click":function($event){return _vm.onClickFilter(filter.key)}}},[_c('span',{staticClass:"text-truncate mr-2",class:{
            'text-primary': ![
              _vm.$t('所有直播'),
              _vm.$t('兒童－全部'),
              _vm.$t('不分齡－全部'),
              '',
            ].includes(_vm.sheets[filter.key]),
          }},[_vm._v(_vm._s(_vm.sheets[filter.key]))]),_c('i',{staticClass:"icon-Chevron-down text-24"})])}),0),(_vm.isDropdownShow)?_c('div',{staticClass:"dropdown-wrapper position-absolute w-100 overflow-hidden",class:{show: _vm.isDropdownShow},on:{"click":_vm.closeSheet}},[(['times'].includes(_vm.currentSelecting))?_c('div',{staticClass:"dropdown"},_vm._l((_vm.currentSheetTags),function(tag){return _c('div',{key:tag,staticClass:"sheet-option d-flex align-items-center justify-content-between position-relative bg-white",on:{"click":function($event){$event.stopPropagation();return _vm.onClickTag(tag)}}},[_vm._v(" "+_vm._s(tag)+" "),_c('i',{directives:[{name:"show",rawName:"v-show",value:(_vm.sheets[_vm.currentSelecting] == tag),expression:"sheets[currentSelecting] == tag"}],staticClass:"icon-Check text-32 text-primary"})])}),0):_c('div',{staticClass:"dropdown h-100 bg-bgcontainer scroll-bar",staticStyle:{"overflow":"overlay"},on:{"click":function($event){$event.stopPropagation();}}},_vm._l((_vm.planTypesDropdown),function(planType,index){return _c('div',{key:planType.planType,on:{"click":function($event){$event.stopPropagation();return _vm.toggleShow(index)}}},[_c('div',{staticClass:"sheet-option d-flex align-items-center justify-content-between position-relative bg-white"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(planType.planType))]),_c('i',{staticClass:"text-32 text-primary",class:planType.show ? 'icon-Circle-minus' : 'icon-Circle-plus'})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(planType.show),expression:"planType.show"}]},_vm._l((planType.plans),function(plan){return _c('div',{key:plan,staticClass:"sheet-option d-flex align-items-center justify-content-between position-relative",on:{"click":function($event){return _vm.onClickTag(plan, planType.planType)}}},[_vm._v(" "+_vm._s(plan)+" "),_c('i',{directives:[{name:"show",rawName:"v-show",value:(
                  _vm.sheets.planType === planType.planType &&
                  _vm.sheets[_vm.currentSelecting] == plan
                ),expression:"\n                  sheets.planType === planType.planType &&\n                  sheets[currentSelecting] == plan\n                "}],staticClass:"icon-Check text-32 text-primary"})])}),0)])}),0)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }