<template>
  <div
    class="filter-board-web bg-white shadow-sm rounded-md"
    :class="isCalendarMode ? 'is-calendar-mode' : 'is-default-mode'"
  >
    <LiveCalendar
      v-if="isCalendarMode"
      :live-dates="liveDates"
      :subscription-date="subscriptionDate"
      :active-date="activeDate"
      class="mb-3"
      @day-click="dayClick"
      @month-click="monthClick"
    />

    <div class="d-flex" :class="isCalendarMode ? 'px-4' : ''">
      <div
        v-click-outside="close"
        class="d-flex search-input-wrapper align-items-center position-relative"
        style="flex: 1"
      >
        <i class="icon-Search text-18"></i>

        <b-form-input
          :placeholder="$t('搜尋直播講堂或老師名稱')"
          class="search-input"
          autocomplete="off"
          :value="keyword"
          @input="changeKeyword"
          @click="onClickSearchInput"
          @keyup.enter="search"
        ></b-form-input>
        <div
          v-if="showHistoryList"
          class="search-history w-100 position-absolute shadow-sm bg-white rounded-sm py-4 px-3"
        >
          <div
            class="clear-header d-flex justify-content-between rounded-sm text-12 align-items-center"
          >
            {{ $t('最近的搜尋') }}
            <div class="cursor-pointer" @click="onClickClear">
              {{ $t('清除') }}
            </div>
          </div>
          <div
            v-for="history in searchHistory"
            :key="history.id"
            class="history cursor-pointer w-100 text-truncate"
            @click="onClickHistory(history)"
          >
            {{ history }}
          </div>
        </div>
      </div>
      <b-button
        variant="primary"
        size="md"
        class="ml-2"
        style="width: 100px"
        @click="search"
        >{{ $t('搜尋') }}</b-button
      >
    </div>

    <div class="mt-4" :class="isCalendarMode ? 'px-4' : ''">
      <b-form-group
        v-for="filter in filters"
        :key="filter.key"
        v-slot="{ariaDescribedby}"
        :label="filter.text"
        label-class="font-weight-bold"
        label-cols="2"
        :class="`is-${filter.key}`"
      >
        <b-form-radio-group
          :id="filter.key"
          :checked="sheets[filter.key]"
          :options="filter.key === 'planType' ? filter.tabs : filter.tags"
          :aria-describedby="ariaDescribedby"
          :name="filter.key"
          :buttons="filter.key === 'planType' || !isCalendarMode"
          :disabled="disabled"
          :stacked="filter.key !== 'planType' && isCalendarMode"
          :class="[filter.key === 'planType' ? 'tabs' : 'tags']"
          @change="onClickTag($event, filter.key)"
        ></b-form-radio-group>
      </b-form-group>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import LiveCalendar from '@/components/Live/LiveCalendar.vue';

export default {
  components: {LiveCalendar},
  props: {
    isCalendarMode: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    activeDate: {
      type: String,
      default: '',
    },
    keyword: {
      type: String,
      default: '',
    },
    searchHistory: {
      type: Array,
      default() {
        return [];
      },
    },
    showHistoryList: {
      type: Boolean,
      default: false,
    },
    liveDates: {
      type: Array,
      default() {
        return [];
      },
    },
    subscriptionDate: {
      type: Array,
      default() {
        return [];
      },
    },
    plans: {
      type: Array,
      default() {
        return [];
      },
    },
    times: {
      type: Array,
      default() {
        return [];
      },
    },
    sheets: {
      type: Object,
      default() {
        return {};
      },
    },
    planTypes: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      masks: {
        title: `YYYY${this.$t('年')}MM${this.$t('月')}`,
      },
      today: this.$day().format('YYYY/MM/DD'),
    };
  },
  computed: {
    attrs() {
      return [
        {
          key: 'activeDay',
          highlight: {
            contentClass: 'active',
          },
          dates: this.activeDate,
          order: 0,
        },
        {
          key: 'today',
          highlight: {
            contentClass: 'today',
          },
          dates: this.today,
          order: 1,
        },
        {
          key: 'beforeDay',
          highlight: {
            contentClass: 'before-day',
          },
          dates: {
            end: this.$day().format(), // Same as before
          },
          order: 2,
        },
        {
          key: 'liveDay',
          customData: {
            dot: true,
          },
          dates: this.liveDates,
          order: 3,
        },
        {
          key: 'subscriptionDay',
          customData: {
            stickerUrl: require('@/assets/img/live-course/course-bell.svg'),
          },
          dates: this.subscriptionDate,
          order: 4,
        },
      ];
    },
    currentPlan() {
      return this.$store.getters['course/currentPlan'];
    },
    expiredDay() {
      return this.$store.getters['user/liveCourseExpiredDay'];
    },
    isKidType() {
      return this.currentPlan?.id?.substring(0, 3) === 'kid';
    },
    noPlans() {
      return this.$store.getters['course/noPlans'];
    },
    filters() {
      return [
        {
          key: 'planType',
          text: this.$t('程度'),
          tabs: this.planTypes,
        },
        {
          key: 'plans',
          text: '',
          tags: this.plans,
        },
        {
          key: 'times',
          text: this.$t('狀態'),
          tags: this.times,
        },
      ];
    },
  },
  methods: {
    changeKeyword(event) {
      this.$emit('input', event);
    },
    onClickTag(name, currentSelecting) {
      const newSheets = _.cloneDeep(this.sheets);
      newSheets[currentSelecting] = name;
      this.$emit('change', newSheets);
    },
    dayClick(day) {
      this.$emit('day-click', day);
    },
    monthClick(month) {
      this.$emit('month-click', month);
    },
    onClickClear() {
      this.$emit('clear-history');
    },
    search() {
      this.$emit('search');
    },
    close() {
      this.$emit('close');
    },
    onClickHistory(keyword) {
      this.$emit('click-history', keyword);
    },
    onClickSearchInput() {
      this.$emit('click-input');
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-board-web {
  width: 100%;
  padding: 40px 100px;
  .collapse-btn {
    border-radius: 10px;
    line-height: 30px;
    padding: 5px 0;
  }
  .search-input-wrapper {
    border: 1px solid #ced4da;
    padding-left: 15px;
    border-radius: $rounded-sm;

    i {
      color: $font-grayscale-3;
    }
  }
  .search-input {
    border: 0;
    padding-left: 8px;
    height: calc(1.5em + 0.75rem);
  }

  .form-group {
    margin-bottom: 0;
    /deep/ .form-row {
      align-items: center;
    }
    &.is-times {
      margin-top: 24px;
    }
  }

  &.is-default-mode {
    /deep/ legend {
      float: auto;
      max-width: 68px;
    }
  }
  &.is-calendar-mode {
    /deep/ legend {
      flex: auto;
      max-width: 56px;
    }
  }

  /deep/ .title {
    width: 42px;
    padding: 0 5px;
    margin-right: 36px;
    line-height: 28px;
    flex: unset;
    max-width: none;
  }

  .tabs {
    /deep/ .btn {
      line-height: 28px;
      width: 120px;
      text-align: center;
      padding: 4px 0;
      border: 1px solid $line-1;
      background: $bgcontainer;
      color: $font-grayscale-1;
      opacity: 0.6;
      &:first-child {
        border-radius: $rounded-sm 0 0 $rounded-sm;
      }
      &:last-child {
        border-radius: 0 $rounded-sm $rounded-sm 0;
      }
      &.active {
        opacity: 1;
        color: $primary;
        background: $white;
        border-color: $primary;
      }
    }
  }

  .is-plans {
    /deep/ .btn {
      margin-top: 16px;
    }
  }
  .tags {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    /deep/ .btn {
      line-height: 18px;
      padding: 6px 8px;
      cursor: pointer;
      background: $bgcontainer;
      font-size: 14px;
      border: 0px;
      border-radius: $rounded-sm;
      flex: unset;
      color: $font-normal;
      &.active {
        font-weight: bold;
        color: $primary;
      }
      &:not(:last-child) {
        margin-right: 16px;
      }
      &:hover {
        color: $primary;
      }
    }
  }

  &.is-calendar-mode {
    width: 415px;
    padding: 16px;
    .tags {
      display: block;
    }

    /deep/ .custom-control {
      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }
    /deep/ .form-row {
      align-items: start;
    }
    .form-group.is-times {
      margin-top: 32px;
    }
    /deep/ .is-times .tags {
      margin-top: 7px;
    }
    /deep/ .is-plans .tags {
      margin-top: 15px;
    }
  }
  .search-history {
    z-index: 1000;
    top: calc(100% + 4px);
    left: 0;
    border: 1px solid $line-2;
  }
  .clear-header {
    color: $font-grayscale-2;
    background: $grayscale-5;
    padding: 5px 16px;
  }
  .history {
    padding: 9px 16px;
    border-bottom: 1px solid $line-2;
  }
}
</style>
