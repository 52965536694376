<template>
  <div
    class="live-list position-relative d-flex flex-column overflow-hiiden"
    :class="{'is-calendar-mode': queryCalendarMode}"
  >
    <SearchHeader
      v-if="isSearchMode && $device.isMobile"
      :keyword="keyword"
      @input="keyword = $event"
      @search="search"
      @close="closeSearchMode"
      @click-input="showHistoryList = true"
    />
    <BaseHeader
      v-else
      :title="$t('直播')"
      :is-title-bold="$isJungo"
      :back-on-click-left="false"
      :has-red-dot="hasVerificationNotification"
      left-icon="Menu"
      :right-text="queryCalendarMode ? $t('列表查詢') : $t('日曆查詢')"
      :mode="$device.isMobile ? 'secondary' : 'primary'"
      @on-click-left="onClickSidebar"
      @on-click-right="onClickChangeMode"
    ></BaseHeader>

    <div
      v-if="showHistoryList && isSearchMode && $device.isMobile"
      class="search-history bg-white w-100 position-absolute shadow-sm bg-white rounded-sm py-4 px-3"
    >
      <div
        class="clear-header d-flex justify-content-between rounded-sm text-12 align-items-center"
      >
        {{ $t('最近的搜尋') }}
        <div class="cursor-pointer" @click="clearHistory">
          {{ $t('清除') }}
        </div>
      </div>
      <div
        v-for="history in searchHistory"
        :key="history.id"
        class="history cursor-pointer w-100 text-truncate"
        @click="onClickHistory(history)"
      >
        {{ history }}
      </div>
    </div>

    <div
      v-if="noPlans || isExpired"
      class="d-flex flex-column justify-content-center align-items-center h-100"
    >
      <EmptyPlaceholder
        icon="live-stream"
        class="h-auto"
        :text="$t('目前沒有直播時數')"
        :sub-text="$t('您可以前往官網購買方案')"
      />
      <b-button
        variant="primary"
        size="md"
        class="mt-3 mx-auto d-block"
        @click="onClick2Web"
      >
        {{ $t('前往官網了解') }}
      </b-button>
    </div>

    <template v-else>
      <div
        v-if="!$device.isMobile"
        class="live-list-web d-flex scroll-bar position-relative"
        :class="{'is-calendar-mode': queryCalendarMode}"
      >
        <div class="web-container pt-4 w-100">
          <div class="d-flex align-items-center justify-content-between mb-3">
            <b-button
              variant="primary"
              class="d-flex align-items-center"
              size="md"
              @click="onClickChangeMode"
            >
              <i
                :class="
                  queryCalendarMode ? 'icon-Layout-list' : 'icon-Calendar'
                "
                class="mr-1"
              ></i>
              {{ queryCalendarMode ? $t('列表查詢') : $t('日曆查詢') }}
            </b-button>
            <router-link class="link-primary" :to="{name: 'live-course-record'}"
              >{{ $t('直播講堂到期日') }}：{{ expiredAt }}</router-link
            >
          </div>
          <div
            class="d-flex"
            :class="queryCalendarMode ? 'flex-row' : 'flex-column'"
          >
            <FilterBoardWeb
              :keyword="keyword"
              :plans="planTags"
              :times="timeTags"
              :sheets="sheets"
              :plan-types="planTypes"
              :is-calendar-mode="queryCalendarMode"
              :disabled="isPageLoading"
              :live-dates="liveDates"
              :subscription-date="subscriptionDate"
              :active-date="activeDate"
              :search-history="searchHistory"
              :show-history-list="showHistoryList"
              class="left scroll-bar"
              :class="queryCalendarMode ? 'mr-3' : 'mb-md-4 mb-lg-6'"
              @input="keyword = $event"
              @change="changeSheets"
              @day-click="dayClick"
              @month-click="monthClick"
              @clear-history="clearHistory"
              @click-history="onClickHistory"
              @search="search"
              @close="showHistoryList = false"
              @click-input="showHistoryList = true"
            />

            <div
              class="right w-100 position-relative"
              style="flex: 1"
              :class="
                queryCalendarMode ? 'p-3 bg-white rounded-md shadow-sm' : ''
              "
            >
              <p v-if="queryCalendarMode" class="text-20 font-weight-bold pl-3">
                {{ activeDateText }}
              </p>
              <div
                class="list-wrapper scroll-bar position-relative d-flex flex-column"
                :class="
                  queryCalendarMode ? 'p-3 bg-bgcontainer rounded-md' : ''
                "
              >
                <div v-if="streamingList.length" class="title mb-3">
                  <p class="font-weight-bold text-20 mb-2">
                    {{ $t('進行中') }}
                  </p>
                  <div class="divider"></div>
                </div>
                <div v-if="streamingList.length" class="mb-6">
                  <LiveCard
                    v-for="liveCourse of streamingList"
                    :key="liveCourse.liveCourseId"
                    :live-course="liveCourse"
                    @toggleNotification="toggleNotification"
                    @enterLiveRoom="enterLiveRoom"
                    @watchRecord="watchRecord"
                    @copy="copy"
                  ></LiveCard>
                </div>
                <div class="title mb-3">
                  <p class="text-20 mb-2">
                    <span v-if="queryKeyword" class="d-flex">
                      {{ $t('和') }}
                      <span
                        class="font-weight-bold text-truncate mx-1"
                        style="max-width: 40%"
                      >
                        {{ queryKeyword }}</span
                      >
                      {{ $t('相關的直播課程') }}
                    </span>

                    <span v-else class="font-weight-bold">{{
                      $t('直播講堂列表')
                    }}</span>
                  </p>
                  <div class="divider"></div>
                </div>

                <LoadingWrapper
                  v-if="isPageLoading"
                  :style="queryCalendarMode ? '' : 'height: 350px !important'"
                />

                <EmptyPlaceholder
                  v-else-if="filterLiveList.length === 0"
                  :icon="
                    queryCalendarMode && !queryKeyword
                      ? 'live-stream'
                      : 'Search-question'
                  "
                  :text="emptyText"
                  :sub-text="emptySubText"
                  style="flex: 1"
                  class="my-3"
                />

                <div v-else class="pb-5">
                  <LiveCard
                    v-for="liveCourse of filterLiveList"
                    :key="liveCourse.liveCourseId"
                    :live-course="liveCourse"
                    @toggleNotification="toggleNotification"
                    @enterLiveRoom="enterLiveRoom"
                    @watchRecord="watchRecord"
                    @copy="copy"
                  ></LiveCard>

                  <InfiniteLoading
                    v-if="hasInfinite && !queryCalendarMode"
                    ref="InfiniteLoading"
                    class="infinite-loading"
                    spinner="spiral"
                    force-use-infinite-wrapper=".live-list-web"
                    @infinite="getLiveCourses"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="live-list-mobile d-flex flex-column scroll-bar position-relative"
        :class="{
          'is-calendar-mode': queryCalendarMode,
          'is-search-mode': isSearchMode,
        }"
      >
        <FilterBoardMobile
          :plan-types-arr="planTypesArr"
          :times="timeTags"
          :sheets="sheets"
          :is-page-loading="isPageLoading"
          @change="changeSheets"
        />
        <div class="wrapper d-flex flex-column scroll-bar">
          <div v-if="!isSearchMode" class="px-3 pt-3" @click="toggleSearchMode">
            <div class="search-btn bg-white d-flex align-items-center text-14">
              <i class="icon-Search text-18 mr-2"></i>
              {{ $t('搜尋直播講堂或老師名稱') }}
            </div>
          </div>

          <div
            v-if="queryCalendarMode"
            class="mb-3"
            :class="{'mt-3': !isSearchMode}"
          >
            <LiveCalendar
              v-if="queryCalendarMode"
              :live-dates="liveDates"
              :subscription-date="subscriptionDate"
              :active-date="activeDate"
              class="mb-4"
              @day-click="dayClick"
              @month-click="monthClick"
            />
            <div class="d-flex">
              <p
                class="text-20 font-weight-bold pl-3 m-0 d-flex align-items-center"
              >
                <i class="icon-Calendar text-32 mr-2"></i>
                {{ activeDateText }}
              </p>
            </div>
          </div>

          <div
            v-if="
              ((!queryCalendarMode && streamingList.length) ||
                (queryCalendarMode &&
                  streamingList.length &&
                  liveList.length)) &&
              !isPageLoading
            "
            class="streaming-list px-3 pt-3 mb-3"
          >
            <div class="title mb-3">
              <p class="font-weight-bold text-20 mb-2">
                {{ $t('進行中') }}
              </p>
              <div class="divider"></div>
            </div>
            <LiveCard
              v-for="liveCourse of streamingList"
              :key="liveCourse.liveCourseId"
              :live-course="liveCourse"
              @toggleNotification="toggleNotification"
              @enterLiveRoom="enterLiveRoom"
              @watchRecord="watchRecord"
              @copy="copy"
            ></LiveCard>
          </div>
          <div class="p-3 position-relative" style="flex: 1">
            <LoadingWrapper
              v-if="isPageLoading"
              :style="queryCalendarMode ? 'height: 200px !important' : ''"
            />

            <div v-else class="pb-5 h-100">
              <div v-if="!isPageLoading" class="title mb-3">
                <p class="text-18 mb-2">
                  <span v-if="queryKeyword" class="d-flex">
                    {{ $t('和') }}
                    <span
                      class="font-weight-bold text-truncate mx-1"
                      style="max-width: 40%"
                    >
                      {{ queryKeyword }}</span
                    >
                    {{ $t('相關的直播課程') }}
                  </span>

                  <span v-else class="font-weight-bold">{{
                    $t('直播講堂列表')
                  }}</span>
                </p>
                <div class="divider"></div>
              </div>
              <EmptyPlaceholder
                v-if="filterLiveList.length === 0"
                :icon="emptyIcon"
                :text="emptyText"
                :sub-text="emptySubText"
                style="flex: 1"
              />
              <template v-else>
                <LiveCard
                  v-for="liveCourse of filterLiveList"
                  :key="liveCourse.liveCourseId"
                  :live-course="liveCourse"
                  @toggleNotification="toggleNotification"
                  @enterLiveRoom="enterLiveRoom"
                  @watchRecord="watchRecord"
                  @copy="copy"
                ></LiveCard>

                <InfiniteLoading
                  v-if="hasInfinite && !queryCalendarMode"
                  ref="InfiniteLoading"
                  class="infinite-loading"
                  spinner="spiral"
                  force-use-infinite-wrapper=".wrapper"
                  @infinite="getLiveCourses"
              /></template>
            </div>
          </div>
        </div>
      </div>
    </template>

    <ModalNewRulesGuide
      :show="isGuideShow"
      :img="newRulesImg"
      :has-btn="true"
      :title="$t('貼心小提醒！')"
      @open="openPdf"
      @close="closeGuide"
    ></ModalNewRulesGuide>
  </div>
</template>

<script>
import BaseHeader from '@/components/Base/BaseHeader';
import LiveCard from '@/components/Live/LiveCard.vue';
import InfiniteLoading from 'vue-infinite-loading';
import FilterBoardWeb from '@/components/Live/FilterBoardWeb.vue';
import LoadingWrapper from '@/components/Base/LoadingWrapper.vue';
import FilterBoardMobile from '@/components/Live/FilterBoardMobile.vue';
import LiveCalendar from '@/components/Live/LiveCalendar.vue';
import SearchHeader from '@/components/Base/SearchHeader.vue';
import ModalNewRulesGuide from '@/components/Modal/ModalNewRulesGuide.vue';
import EmptyPlaceholder from '@/components/Base/EmptyPlaceholder.vue';

import liveCourseService from '@/services/liveCourse';
import plansMixin from '@/mixin/plans';
import {Browser} from '@capacitor/browser';
import copyToClipboard from '@/lib/base/copyToClipboard.js';
import _ from 'lodash';
import newRulesImg from '@/assets/img/guide/live-course-guide.png';
import {getLocalData, saveLocalData} from '@/lib/base/localData';
import {manifest, stage} from '@/constant/env.js';
import BackEvent from '@/lib/base/backEvent.js';
import delay from '@/lib/base/delay.js';

export default {
  components: {
    BaseHeader,
    EmptyPlaceholder,
    ModalNewRulesGuide,
    LiveCard,
    InfiniteLoading,
    FilterBoardWeb,
    FilterBoardMobile,
    LoadingWrapper,
    LiveCalendar,
    SearchHeader,
  },
  mixins: [plansMixin],
  data() {
    return {
      isFirstFetch: true,
      isAscending: true,
      isPageLoading: false,
      hasInfinite: false,
      lastEndAt: null,
      lastId: null,
      liveList: [],
      calendarLiveList: {},
      streamingList: [],
      keyword: '',
      searchHistory: [],
      showHistoryList: false,
      currentSelecting: '',
      isSearchMode: false,
      activeDate: this.$day().format('YYYY/MM/DD'),
      activeMonth: 0,
      sheets: {
        planType: this.$t('不分齡'),
        plans: this.$t('不分齡－全部'),
        times: this.$t('所有直播'),
      },
      timeTags: [
        this.$t('所有直播'),
        this.$t('尚未開始的直播'),
        this.$t('已結束的直播（重播）'),
      ],
      newRulesImg,
      isGuideShow: false,
      dayMap: [
        this.$t('日'),
        this.$t('一'),
        this.$t('二'),
        this.$t('三'),
        this.$t('四'),
        this.$t('五'),
        this.$t('六'),
      ],
    };
  },
  provide() {
    return {
      isPageLoading: () => this.isPageLoading,
    };
  },
  inject: ['appNow'],
  computed: {
    now() {
      const now = this.appNow();
      return now;
    },
    limit() {
      return this.queryCalendarMode ? 300 : 10;
    },
    filterLiveList() {
      if (this.queryCalendarMode) {
        return this.liveList.filter(
          (course) => this.$day(this.activeDate).isSame(course.startDate, 'day')
          //  &&
          // !(this.now >= course.startAt && this.now < course.endAt)
        );
      }
      return this.liveList;
      // .filter(
      //   (course) => !(this.now >= course.startAt && this.now < course.endAt)
      // );
    },
    liveDates() {
      return this.liveList.map((live) => {
        return this.$day(live.startDate).format('YYYY/MM/DD');
      });
    },
    subscriptionDate() {
      return this.liveList
        .filter((live) => live.isSubscription)
        .map((live) => {
          return this.$day(live.startDate).format('YYYY/MM/DD');
        });
    },
    activeDateText() {
      const date = this.$day(this.activeDate).format('YYYY年MM月DD日');
      const day = this.$day(this.activeDate).format('d');
      return `${date}（${this.$t('週')}${this.dayMap[day]}）`;
    },
    hasVerificationNotification() {
      return this.$store.state.env.hasVerificationNotification;
    },
    expiredDay() {
      return this.$store.getters['user/liveCourseExpiredDay'];
    },
    expiredAt() {
      return this.$day(this.userData.liveCourseEndAt).format('YYYY/MM/DD');
    },
    isExpired() {
      return this.expiredDay <= 0;
    },
    userData() {
      return this.$store.getters['user/userData'];
    },
    currentPlan() {
      return this.$store.getters['course/currentPlan'];
    },
    noPlans() {
      return this.$store.getters['course/noPlans'];
    },
    emptyIcon() {
      return this.liveList.length === 0 ? 'Search-question' : 'live-stream';
    },
    emptyText() {
      let text = '';
      if (this.liveList.length === 0) {
        text = this.$t('沒有找到相關直播課');
      } else {
        text = this.$t('沒有直播');
      }
      return text;
    },
    emptySubText() {
      let text = '';
      if (this.queryCalendarMode && this.liveList.length !== 0) {
        text =
          this.$day(this.activeDate).format('MM月DD日') +
          this.$t('沒有直播講堂');
      } else {
        text = this.$t('試試其他篩選條件或關鍵字');
      }
      return text;
    },
    isKidType() {
      return this.sheets.planType === this.$t('兒童');
    },
    planTypesArr() {
      const planTypesArr = this.planTypes.map((planType) => {
        if (planType === this.$t('不分齡')) {
          return {
            planType,
            show: !this.isKidType,
            plans: [this.$t('不分齡－全部'), ...this.adultPlans],
          };
        } else {
          return {
            planType,
            show: this.isKidType,
            plans: [this.$t('兒童－全部'), ...this.kidPlans],
          };
        }
      });
      return planTypesArr;
    },
    adultPlans() {
      return this.$store.getters['liveCourse/plans']
        .filter((tag) => tag.isAdult)
        .map((tag) => tag.name);
    },
    kidPlans() {
      return this.$store.getters['liveCourse/plans']
        .filter((tag) => tag.isKid)
        .map((tag) => tag.name);
    },
    plans() {
      return this.isKidType ? this.kidPlans : this.adultPlans;
    },
    planTypes() {
      return [this.$t('不分齡'), this.$t('兒童')];
    },
    tags() {
      return this.$store.getters['liveCourse/tags'];
    },
    planTags() {
      const plans = _.cloneDeep(this.plans);
      const newPlans = plans.map((plan) => {
        const text = plan;
        return text;
      });

      return [
        this.isKidType ? this.$t('兒童－全部') : this.$t('不分齡－全部'),
        ..._.uniq(newPlans),
      ];
    },
    queryKeyword() {
      return this.$route.query.search || '';
    },
    queryPlan() {
      return this.$route.query.plan;
    },
    queryTime() {
      return this.$route.query.time;
    },
    queryCalendarMode() {
      return this.$route.query?.isCalendarMode === 'true' || false;
    },
  },
  async created() {
    if (this.queryKeyword) {
      this.keyword = this.queryKeyword;
      if (this.$device.isMobile) {
        this.isSearchMode = true;
      }
      try {
        this.$gtag.event('view_search_results', {
          search_term: this.keyword,
          items: [{item_id: 'liveCourse'}],
        });
      } catch (error) {
        console.log(error);
      }
    }
    this.getTags();
    this.searchHistory =
      JSON.parse(localStorage.getItem('search-history')) || [];
  },
  methods: {
    async getQueryHistory() {
      const data = JSON.parse(localStorage.getItem('query-history')) || {};
      const recordObj = {};
      const localObj = {};
      if (this.queryPlan) {
        const mapData = this.tags.find((tag) => tag.name === this.queryPlan);
        const isKidAllType = this.queryPlan === this.$t('兒童－全部');

        if (mapData || isKidAllType) {
          this.sheets.planType =
            mapData?.isKid || isKidAllType
              ? this.$t('兒童')
              : this.$t('不分齡');

          this.sheets.plans = this.queryPlan;
          localObj.plan = this.queryPlan;
        }
      } else if (data.plan) {
        const mapData = this.tags.find((tag) => tag.name === data.plan);
        const isKidAllType = data.plan === this.$t('兒童－全部');

        if (mapData || isKidAllType) {
          this.sheets.planType =
            mapData?.isKid || isKidAllType
              ? this.$t('兒童')
              : this.$t('不分齡');

          this.sheets.plans = data.plan;
          recordObj.plan = data.plan;
        }
      }

      if (this.queryCalendarMode) {
        localObj.isCalendarMode = JSON.parse(this.queryCalendarMode);
      } else if (data.isCalendarMode) {
        recordObj.isCalendarMode = JSON.parse(data.isCalendarMode);
      }

      if (this.queryTime) {
        this.sheets.times = this.queryTime;
      }

      if (!_.isEmpty(recordObj)) {
        await this.updateRecord(recordObj);
      }
      if (!_.isEmpty(localObj)) {
        this.saveQueryHistory(localObj);
      }
    },
    saveQueryHistory(query) {
      const data = {};
      if (query.isCalendarMode) {
        data.isCalendarMode = JSON.parse(query.isCalendarMode);
      }
      if (query.plan) {
        data.plan = query.plan;
      }
      saveLocalData('query-history', JSON.stringify(data));
    },
    init() {
      this.liveList = [];
      this.isFirstFetch = true;
      this.lastEndAt = null;
      this.lastId = null;
      this.isAscending = true;
    },
    async updateRecord(obj) {
      try {
        const query = _.cloneDeep(this.$route.query || {});
        for (const [key, value] of Object.entries(obj)) {
          query[key] = value;
        }
        this.saveQueryHistory(query);

        await this.$router.replace({query});
      } catch (error) {
        throw error;
      }
    },
    async search() {
      if (this.isPageLoading || this.keyword === this.queryKeyword) return;
      try {
        this.$gtag.event('view_search_results', {
          search_term: this.keyword,
          items: [{item_id: 'liveCourse'}],
        });
      } catch (error) {
        console.log(error);
      }
      await this.updateRecord({search: this.keyword});

      this.saveSearchHistory(this.keyword);
      this.getSearchHistory();
      this.showHistoryList = false;
      try {
        this.isPageLoading = true;

        if (this.queryCalendarMode) {
          this.liveList = await this.processFilterLiveList(
            this.calendarLiveList[this.activeMonth]
          );
        } else {
          this.init();

          await this.getLiveCourses();
        }
      } catch (error) {
        this.$Message.error(this.$t('發生未知錯誤，再試一次'));
      } finally {
        this.isPageLoading = false;
      }
    },
    clearHistory() {
      this.searchHistory = [];

      this.saveSearchHistory();
      this.getSearchHistory();
    },
    onClickHistory(keyword) {
      this.keyword = keyword;
      this.search();
    },
    getSearchHistory() {
      this.searchHistory = JSON.parse(getLocalData('search-history')) || [];
    },
    saveSearchHistory(keyword) {
      if (this.searchHistory.length >= 10) {
        this.searchHistory.pop();
      }
      if (keyword) {
        this.searchHistory.unshift(keyword);
      }
      saveLocalData('search-history', JSON.stringify(this.searchHistory));
    },
    async getTags() {
      try {
        this.isPageLoading = true;
        await this.$store.dispatch('liveCourse/getTags');
        await this.getQueryHistory();

        if (!this.isExpired) {
          if (this.queryCalendarMode) {
            await Promise.all([
              this.getCalendarLiveCourses(),
              this.getCurrentLiveCourses(),
            ]);
          } else {
            await Promise.all([
              this.getLiveCourses(),
              this.getCurrentLiveCourses(),
            ]);
          }
        }
      } catch (error) {
        this.$Message.error(this.$t('發生未知錯誤，再試一次'));
      } finally {
        this.isPageLoading = false;
      }
    },
    processApiData(limit, isAscending, lastId, lastEndAt) {
      let plans = [];

      if (
        [this.$t('不分齡－全部'), this.$t('兒童－全部')].includes(
          this.sheets.plans
        )
      ) {
        plans = [this.isKidType ? this.$t('兒童') : this.$t('不分齡')];
      } else {
        plans = [this.sheets.plans];
      }

      const tags = [...plans];

      const params = {
        tags: JSON.stringify(tags),
        limit,
        isAscending,
        lastEndAt,
        lastId,
      };

      switch (this.sheets.times) {
        case this.$t('所有直播'):
          if (isAscending) {
            params.startAt = this.now;
          } else {
            params.startAt = this.$day().subtract(90, 'day').valueOf();
            params.endAt = this.now;
          }
          break;
        case this.$t('尚未開始的直播'):
          params.startAt = this.now;
          break;
        case this.$t('已結束的直播（重播）'):
          params.startAt = this.$day()
            .subtract(90, 'day')
            .startOf('day')
            .valueOf();
          params.endAt = this.now;
          params.isAscending = false;
          break;
      }
      if (this.keyword) {
        params.keyword = this.keyword;
      }

      return params;
    },
    async getLiveCourses($state) {
      try {
        let liveList = [];

        if (this.isFirstFetch || (this.lastEndAt && this.lastId)) {
          const params = await this.processApiData(
            this.limit,
            this.isAscending,
            this.lastId,
            this.lastEndAt
          );
          const result = await liveCourseService.getLiveCoursesByTags(params);
          liveList = result;
          this.lastEndAt = liveList[liveList.length - 1]?.endAt;
          this.lastId = liveList[liveList.length - 1]?.liveCourseId;
        }

        if (
          liveList.length < this.limit &&
          this.isAscending &&
          this.sheets.times !== this.$t('尚未開始的直播')
        ) {
          this.lastId = null;
          this.lastEndAt = null;
          this.isAscending = false;
          const params = await this.processApiData(
            this.limit - liveList.length,
            this.isAscending
          );
          const result = await liveCourseService.getLiveCoursesByTags(params);

          liveList.push(...result);

          this.lastEndAt = liveList[liveList.length - 1]?.endAt;
          this.lastId = liveList[liveList.length - 1]?.liveCourseId;
        }

        this.liveList.push(...liveList);

        if ($state) {
          if (liveList.length === this.limit) {
            $state.loaded();
          } else {
            $state.complete();
          }
        } else if (this.queryCalendarMode && liveList.length === this.limit) {
          return this.getLiveCourses();
        }

        this.hasInfinite = liveList.length === this.limit;
        this.isFirstFetch = false;
      } catch (error) {
        throw error;
      }
    },
    async getCalendarLiveCourses() {
      try {
        if (
          this.calendarLiveList[this.activeMonth] != null &&
          !this.isFirstFetch
        ) {
          return;
        }
        const curretMonth = this.activeMonth;

        const params = {
          limit: this.limit,
          tags: JSON.stringify([
            this.isKidType ? this.$t('兒童') : this.$t('不分齡'),
          ]),
        };

        const isSameMonth =
          this.$day(curretMonth).format('YYYY-MM') ==
          this.$day().format('YYYY-MM');

        let isBeforeToday = false;
        let isAfterToday = false;

        if (!isSameMonth) {
          const timestampCompare =
            this.$day(curretMonth).valueOf() - this.$day().valueOf() > 0;
          isAfterToday = timestampCompare;
          isBeforeToday = !timestampCompare;
        }

        const removeDayTimestamp = this.$day()
          .subtract(90, 'day')
          .startOf('day')
          .valueOf();

        const startOfMonth = this.$day(curretMonth).startOf('month').valueOf();
        const endOfMonth = this.$day(curretMonth).endOf('month').valueOf();

        const isBeforeRemoveDate = removeDayTimestamp > startOfMonth;
        const isSameMonthWithRemoveDate = this.$day()
          .subtract(90, 'day')
          .startOf('day')
          .isSame(this.$day(curretMonth).startOf('month'), 'month');
        if (isBeforeRemoveDate && !isSameMonthWithRemoveDate) {
          this.calendarLiveList[curretMonth] = [];
          return;
        }
        if (isSameMonth) {
          params.isAscending = this.isAscending;
          if (this.isAscending) {
            params.startAt = this.now;
            params.endAt = endOfMonth;
          } else {
            params.startAt = startOfMonth;
            params.endAt = this.now;
          }
        } else if (isAfterToday) {
          params.isAscending = true;
          params.startAt = startOfMonth;
          params.endAt = endOfMonth;
        } else if (isBeforeToday) {
          params.isAscending = false;
          params.startAt = isBeforeRemoveDate
            ? removeDayTimestamp
            : startOfMonth;

          params.endAt = endOfMonth;
        }

        const result = await liveCourseService.getLiveCoursesByTags(params);

        if (this.calendarLiveList[curretMonth] == null) {
          this.calendarLiveList[curretMonth] = [];
        }

        this.calendarLiveList[curretMonth].push(...result);

        this.liveList = this.processFilterLiveList(
          this.calendarLiveList[curretMonth]
        );

        if (isSameMonth && this.isAscending) {
          this.isAscending = false;
          return this.getCalendarLiveCourses();
        } else {
          this.isAscending = true;
        }

        this.isFirstFetch = false;
      } catch (error) {
        throw error;
      }
    },
    async getCurrentLiveCourses() {
      try {
        const params = {
          isAscending: true,
          startAt: this.$day().subtract(5, 'minutes').valueOf(),
          endAt: this.$day().add(30, 'minutes').valueOf(),
          limit: this.limit,
        };
        const apis = [
          liveCourseService.getLiveCoursesByTags({
            tags: JSON.stringify([this.$t('不分齡')]),
            ...params,
          }),
          liveCourseService.getLiveCoursesByTags({
            tags: JSON.stringify([this.$t('兒童')]),
            ...params,
          }),
        ];
        const result = await Promise.all(apis);
        this.streamingList = _.flatten(result);
        // .filter(
        //   (res) => this.now >= res.startAt && this.now < res.endAt
        // );
      } catch (error) {
        throw error;
      }
    },
    processFilterLiveList(liveList) {
      const filterPlan = ![
        this.$t('不分齡－全部'),
        this.$t('兒童－全部'),
      ].includes(this.sheets.plans);
      const isOver = this.sheets.times === this.$t('已結束的直播（重播）');
      const isNotYet = this.sheets.times === this.$t('尚未開始的直播');
      return liveList.filter((course) => {
        return (
          (course.tags.join('').indexOf(this.queryKeyword) != -1 ||
            course.name.indexOf(this.queryKeyword) != -1) &&
          (filterPlan ? course.tags.includes(this.queryPlan) : true) &&
          (isOver ? this.$day().isAfter(this.$day(course.endAt)) : true) &&
          (isNotYet ? this.$day().isBefore(this.$day(course.startAt)) : true)
        );
      });
    },
    async toggleNotification(liveCourse) {
      if (!JSON.parse(getLocalData('has-live-course-guide'))) {
        this.isGuideShow = true;
      }
      const index = this.liveList.findIndex(
        (live) => live.liveCourseId === liveCourse.liveCourseId
      );
      try {
        this.$set(this.liveList[index], 'isLoading', true);

        if (liveCourse?.isSubscription) {
          await liveCourseService.deleteNotification(liveCourse.liveCourseId);

          await delay(1000); // animation need
          this.$delete(this.liveList[index], 'isSubscription');
        } else {
          await liveCourseService.postNotification(
            liveCourse.liveCourseId,
            this.currentPlan.id
          );
          await delay(1000); // animation need
          this.$set(this.liveList[index], 'isSubscription', true);
        }
      } catch (error) {
        this.$Message.error(this.$t('設定提醒失敗'));
      } finally {
        this.$set(this.liveList[index], 'isLoading', false);
      }
    },
    async enterLiveRoom(liveCourse) {
      Browser.open({
        url: liveCourse.streamingJoinUrl,
        presentationStyle: 'popover',
      });
      await this.sendRecord(liveCourse.liveCourseId);
    },
    async watchRecord(liveCourse) {
      try {
        this.$store.commit('env/setIsLoading', true);
        const {data: url} = await liveCourseService.getLiveCoursesRecord({
          liveCourseId: liveCourse.liveCourseId,
          classId: liveCourse.classId,
        });
        if (!url) {
          return this.$Message.error(this.$t('此直播無法回放'));
        }
        await Browser.open({
          url,
          presentationStyle: 'popover',
        });
        await this.sendRecord(liveCourse.liveCourseId);
      } catch (error) {
        this.$Message.error(this.$t('發生未知錯誤，再試一次'));
      } finally {
        this.$store.commit('env/setIsLoading', false);
      }
    },
    async sendRecord(livecourseId) {
      await liveCourseService.postLiveCourseRecord(
        livecourseId,
        this.$day().valueOf()
      );
    },
    copy(meetingId) {
      copyToClipboard(meetingId);
      this.$Message.success(this.$t('複製成功'));
    },
    async changeSheets(newSheets) {
      if (this.isPageLoading) {
        return;
      }
      try {
        this.isPageLoading = true;

        const isPlanTypeChange = newSheets.planType !== this.sheets.planType;

        if (isPlanTypeChange) {
          this.sheets = {
            planType: newSheets.planType,
            plans:
              newSheets.planType === this.$t('兒童')
                ? this.$t('兒童－全部')
                : this.$t('不分齡－全部'),
            times: this.$t('所有直播'),
          };
          if (this.$device.isMobile) {
            this.sheets.plans = newSheets.plans;
          }
        } else {
          this.sheets = _.cloneDeep(newSheets);
        }

        await this.updateRecord({
          plan: this.sheets.plans,
          time: this.sheets.times,
        });

        if (this.queryCalendarMode) {
          if (isPlanTypeChange) {
            this.init();
            this.calendarLiveList = {};
            await this.getCalendarLiveCourses();
          } else {
            this.liveList = this.processFilterLiveList(
              this.calendarLiveList[this.activeMonth]
            );
          }
        } else {
          this.init();
          this.isAscending =
            this.sheets.times !== this.$t('已結束的直播（重播）');

          await this.getLiveCourses();
        }
      } catch (error) {
        this.$Message.error(this.$t('發生未知錯誤，再試一次'));
      } finally {
        this.isPageLoading = false;
      }
    },
    closeGuide() {
      this.isGuideShow = false;
      saveLocalData('has-live-course-guide', JSON.stringify(true));
    },
    async onClick2Web() {
      let url = `${manifest.homePageUrl}/courses?coursesType=isLiveCourse`;

      if (stage === 'beta') {
        url =
          'http://ec2-13-230-41-83.ap-northeast-1.compute.amazonaws.com/courses?coursesType=isLiveCourse';
      }
      await Browser.open({
        url,
        presentationStyle: 'popover',
      });
    },
    onClickSidebar() {
      this.$store.commit('env/setIsSidebarShow', true);
      BackEvent.addEvent(() => {
        this.$store.commit('env/setIsSidebarShow', false);
      });
    },
    async onClickChangeMode() {
      if (this.isPageLoading) {
        return;
      }
      try {
        this.isPageLoading = true;
        await this.updateRecord({isCalendarMode: !this.queryCalendarMode});

        this.calendarLiveList = {};
        this.init();
        this.streamingList = [];

        if (this.queryCalendarMode) {
          await Promise.all([
            this.getCalendarLiveCourses(),
            this.getCurrentLiveCourses(),
          ]);
        } else {
          await Promise.all([
            this.getLiveCourses(),
            this.getCurrentLiveCourses(),
          ]);
        }
      } catch (error) {
        this.$Message.error(this.$t('發生未知錯誤，再試一次'));
      } finally {
        this.isPageLoading = false;
      }
    },
    toggleSearchMode() {
      if (this.isPageLoading) {
        return;
      }
      this.showHistoryList = this.isSearchMode = !this.isSearchMode;
    },
    closeSearchMode() {
      this.isSearchMode = false;
      this.search();
    },
    dayClick(day) {
      this.activeDate = day;
    },
    async monthClick(month) {
      this.activeMonth = month;

      if (this.isPageLoading || this.isFirstFetch) {
        return;
      }
      if (this.calendarLiveList[this.activeMonth] != null) {
        this.liveList = this.calendarLiveList[this.activeMonth];
        return;
      }
      try {
        this.isPageLoading = true;

        this.liveList = [];

        await this.getCalendarLiveCourses();
      } catch (error) {
        this.$Message.error(this.$t('發生未知錯誤，再試一次'));
      } finally {
        this.isPageLoading = false;
      }
    },
    openPdf(type) {
      let url = '';
      switch (type) {
        case 'ios':
          url =
            'https://drive.google.com/file/d/1Ws2SpVj0pJf152Gxe1TEFkdP9OfBejL_/view';
          break;
        case 'android':
          url =
            'https://drive.google.com/file/d/1yJouTAlEEg7OB-NulWAcMh-PLmqLtx2u/view';
          break;
      }
      Browser.open({
        url,
        presentationStyle: 'popover',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.live-list {
  height: calc(
    100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom) - 62px
  );
  @media screen and (min-width: 768px) {
    height: 100vh;
  }
}

.live-list-web {
  overflow: overlay;
  height: 100%;

  .web-container {
    margin: 0 auto;
    padding: 0 32px;
    max-width: calc(100vw - 120px);
    @media screen and (min-width: 1280px) {
      max-width: 1172px;
    }
  }
  &.is-calendar-mode {
    .left,
    .right {
      overflow: overlay;
      height: calc(100vh - 80px - 38px - 24px - 24px - 16px);
    }
    .list-wrapper {
      overflow: overlay;
      border: 1px solid $line-2;
      height: calc(100% - 30px - 16px);
    }
  }
}

.live-list-mobile {
  height: calc(100% - 56px);

  .wrapper {
    height: calc(100% - 48px);
    overflow: overlay;
  }
  .search-btn {
    padding: 10px 15px;
    border-radius: 30px;
    border: 1px solid $line-2;
    color: $font-grayscale-1;
  }
  .icon-Search {
    color: $font-grayscale-3;
  }
}

.search-history {
  top: 56px;
  z-index: 1000;
  height: calc(100% - 56px);
}
.clear-header {
  color: $font-grayscale-2;
  background: $grayscale-5;
  padding: 5px 16px;
}
.history {
  padding: 9px 16px;
  border-bottom: 1px solid $line-2;
}

.divider {
  height: 1px;
  background: $line-2;
}

.infinite-loading /deep/ .infinite-status-prompt {
  padding: 0 !important;
  .loading-spiral {
    margin-top: 16px;
    padding: 0;
    width: 16px;
    height: 16px;
    border: 2px solid $secondary;
    border-right-color: transparent;
  }
}
</style>
