var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"live-card w-100 bg-white position-relative p-3 overflow-hidden"},[_c('div',{staticClass:"w-100"},[_c('div',{staticClass:"header d-flex justify-content-between align-items-center text-14"},[(_vm.type)?_c('p',{staticClass:"m-0"},[_vm._v("【"+_vm._s(_vm.type)+"】")]):_vm._e(),_c('p',{staticClass:"m-0"},[(_vm.status === 'live')?_c('span',{staticClass:"d-flex align-items-center"},[_vm._m(0),_vm._v(" 直播中 ")]):(_vm.status === 'incoming')?_c('span',[_vm._v("即將開始")]):_vm._e()])]),_c('div',[_vm._l((_vm.names),function(name,index){return _c('p',{key:name,staticClass:"title font-weight-bold text-18 mb-0",class:{
            'text-primary': index == _vm.names.length - 1 && _vm.names.length > 1,
          }},[_vm._v(" "+_vm._s(name)+" ")])}),(_vm.showTags)?_c('div',{staticClass:"hashtags d-flex flex-wrap text-14"},_vm._l((_vm.filterTags),function(tag){return _c('span',{key:tag,staticClass:"mr-1 mt-2 rounded-sm",class:{'high-light': tag.indexOf('班') !== -1}},[_vm._v(_vm._s(tag))])}),0):_vm._e()],2)]),_c('div',{staticClass:"divider"}),_c('div',{staticClass:"w-100 d-flex align-items-center justify-content-between"},[_c('div',[_c('p',{staticClass:"info-text text-14 d-flex align-items-center"},[_c('i',{staticClass:"icon-Clock text-20 mr-2"}),_vm._v(_vm._s(_vm.date)+" ")]),(['over', 'record'].includes(_vm.status))?_c('p',{staticClass:"warning-text text-14 d-flex align-items-center"},[_c('i',{staticClass:"icon-Clock text-20 mr-2"}),_vm._v(" "+_vm._s(_vm.removeDate)+" ")]):_vm._e(),(_vm.meetingIdText)?_c('p',{staticClass:"info-text text-14 cursor-pointer d-flex align-items-center",on:{"click":_vm.copy}},[_c('i',{staticClass:"icon-Copy text-primary text-20 mr-2"}),_vm._v(" "+_vm._s(_vm.meetingIdText)+" ")]):_vm._e()]),_c('div',{staticClass:"bun-wrapper"},[(['notStart'].includes(_vm.status) && _vm.showSubscription)?_c('b-button',{staticClass:"notification-btn d-flex align-items-center",class:{
            'is-loading': _vm.liveCourse.isLoading,
            'is-subscription': _vm.liveCourse.isSubscription,
          },attrs:{"variant":_vm.liveCourse.isSubscription ? 'outline-primary' : 'primary',"size":"sm"},on:{"click":_vm.toggleNotification}},[_c('b-spinner'),_c('i',{staticClass:"icon-Check text-16 mr-2"}),_c('i',{staticClass:"icon-Bell text-16 mr-2"}),_vm._v(" "+_vm._s(!_vm.liveCourse.isSubscription ? _vm.$t('通知我') : _vm.$t('已設通知'))+" ")],1):(['live', 'incoming'].includes(_vm.status) && _vm.meetingIdText)?_c('b-button',{staticStyle:{"padding":"6px 14px"},attrs:{"variant":"primary","size":"sm"},on:{"click":_vm.enterLiveRoom}},[_vm._v(" "+_vm._s(_vm.$t('進入直播'))+" ")]):(_vm.liveCourse.isCompleted)?_c('b-button',{staticClass:"d-flex align-items-center",staticStyle:{"padding":"6px 14px"},attrs:{"variant":"primary","size":"sm"},on:{"click":_vm.watchRecord}},[_c('i',{staticClass:"icon-Circle-play mr-1 text-16"}),_vm._v(" "+_vm._s(_vm.$t('重播'))+" ")]):_vm._e()],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"live-indicator mr-2"},[_c('div',{staticClass:"red-dot"}),_c('div',{staticClass:"pulse one"}),_c('div',{staticClass:"pulse two"})])
}]

export { render, staticRenderFns }