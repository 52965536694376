<template>
  <div class="search-header bg-secondary d-flex align-items-center px-3 py-2">
    <div
      class="d-flex search-input-wrapper align-items-center position-relative bg-white"
      style="flex: 1"
    >
      <i class="icon-Search text-18"></i>
      <b-form-input
        :placeholder="$t('搜尋直播講堂或老師名稱')"
        class="search-input"
        autocomplete="off"
        autofocus
        enterkeyhint="search"
        :value="keyword"
        @input="changeKeyword"
        @keyup.enter="search"
        @click.stop="onClickSearchInput"
      ></b-form-input>
      <i
        v-if="keyword"
        class="icon-Circle-X text-24 mr-2"
        @click="changeKeyword('')"
      ></i>
    </div>
    <p class="m-0 text-white ml-3" @click="close">{{ $t('取消') }}</p>
  </div>
</template>

<script>
export default {
  props: {
    keyword: {
      type: String,
      default: '',
    },
  },
  data() {
    return {};
  },
  methods: {
    changeKeyword(event) {
      this.$emit('input', event);
    },
    search() {
      this.$emit('search');
    },
    close() {
      this.$emit('input', '');
      this.$emit('close');
    },
    onClickSearchInput() {
      this.$emit('click-input');
    },
  },
};
</script>

<style lang="scss" scoped>
.search-header {
  .search-input-wrapper {
    border: 1px solid #ced4da;
    padding-left: 15px;
    border-radius: 30px;
    overflow: hidden;

    i {
      color: $font-grayscale-3;
    }
  }
  .search-input {
    border: none;
  }
}
</style>
