<template>
  <div>
    <b-modal
      ref="modal"
      class="modal-confirm"
      content-class="rounded-10 position-relative overflow-hidden border-0"
      size="xl"
      hide-footer
      hide-header
      no-close-on-backdrop
      :visible="show"
      centered
    >
      <div
        class="header d-flex align-items-center justify-content-between font-weight-bold"
      >
        {{ title }}
        <i
          class="icon-X text-primary d-block text-32 cursor-pointer"
          @click="$emit('close')"
        ></i>
      </div>
      <div class="content position-relative">
        <img class="img-fluid" :src="img" alt="" />
        <div v-if="hasBtn" class="btn-wrap position-absolute">
          <b-button
            variant="primary"
            size="lg"
            block
            class="mb-2 font-weight-bold"
            @click="$emit('open', 'ios')"
          >
            {{ $t('iOS 設定方式') }}
          </b-button>
          <b-button
            variant="primary"
            size="lg"
            block
            class="font-weight-bold"
            @click="$emit('open', 'android')"
          >
            {{ $t('安卓設定方式') }}
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    img: {
      type: String,
      default: '',
    },
    hasBtn: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onClickBtn() {
      this.$emit('');
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .modal-dialog {
  width: 300px;
  height: 300px;
  margin: 0 auto;
  color: $font-grayscale-1;
  .modal-body {
    padding: 0;
  }
}
.header {
  padding: 14px 8px 14px 16px;
  border-bottom: 1px solid $line-1;
}
img {
  width: 300px;
}
.btn-wrap {
  left: 20px;
  bottom: 32px;
}
</style>
