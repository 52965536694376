<template>
  <div class="calendar text-center bg-white position-relative">
    <vc-calendar
      ref="calendar"
      class="custom-calendar"
      :masks="masks"
      :attributes="attrs"
      :disable-page-swipe="true"
      is-expanded
      @dayclick="dayClick"
      @update:from-page="monthUpdate"
    >
      <template v-slot:header-left-button>
        <div @click.stop="onClickStep('prev')">
          <i class="icon-Chevron-left text-32 d-block"></i>
        </div>
      </template>
      <template v-slot:header-right-button>
        <div @click.stop="onClickStep('next')">
          <i class="icon-Chevron-right text-32 d-block"></i>
        </div>
      </template>
      <template v-slot:day-content="{day, attributes, dayEvents}">
        <div
          v-if="dayEvents.click"
          class="vc-day-content vc-focusable"
          role="button"
          :class="getHighlightClass(attributes)"
          @click="dayEvents.click"
        >
          <span class="day-label">{{ day.day }}</span>
          <figure
            v-if="attributes?.[attributes.length - 1]?.customData?.stickerUrl"
            class="day-sticker"
          >
            <img
              :src="attributes[attributes.length - 1].customData.stickerUrl"
            />
          </figure>
          <div
            v-if="attributes?.[attributes.length - 1]?.customData?.dot"
            class="day-dot"
          ></div>
        </div>
      </template>
    </vc-calendar>
    <span
      class="icon-Calendar position-absolute"
      :class="{
        'is-disable': today === $day(activeDate).format('YYYY/MM/DD'),
      }"
      @click="todayClick"
    ></span>
  </div>
</template>

<script>
export default {
  props: {
    activeDate: {
      type: String,
      default: '',
    },
    liveDates: {
      type: Array,
      default() {
        return [];
      },
    },
    subscriptionDate: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      masks: {
        title: `YYYY${this.$t('年')}MM${this.$t('月')}`,
      },
      today: this.$day().format('YYYY/MM/DD'),
      month: '',
    };
  },
  inject: ['isPageLoading'],
  computed: {
    isLoading() {
      return this.isPageLoading();
    },
    attrs() {
      return [
        {
          key: 'activeDay',
          highlight: {
            contentClass: 'active',
          },
          dates: this.activeDate,
          order: 0,
        },
        {
          key: 'today',
          highlight: {
            contentClass: 'today',
          },
          dates: this.today,
          order: 1,
        },
        {
          key: 'beforeDay',
          highlight: {
            contentClass: 'before-day',
          },
          dates: {
            end: this.$day().format(),
          },
          order: 2,
        },
        {
          key: 'liveDay',
          customData: {
            dot: true,
          },
          dates: this.liveDates,
          order: 3,
        },
        {
          key: 'subscriptionDay',
          customData: {
            stickerUrl: require('@/assets/img/live-course/course-bell.svg'),
          },
          dates: this.subscriptionDate,
          order: 4,
        },
      ];
    },
  },
  methods: {
    onClickStep(step) {
      if (this.isLoading) {
        return;
      }

      let year = 0;
      let month = 0;
      let newMonth = null;
      if (step === 'prev') {
        newMonth = this.$day(this.month).subtract(1, 'month');
      } else {
        newMonth = this.$day(this.month).add(1, 'month');
      }

      this.month = `${year}-${month}`;
      year = newMonth.year();
      month = newMonth.month() + 1;

      const calendar = this.$refs.calendar;
      calendar.showPageRange({month, year});

      this.$emit('month-click', this.month);
    },
    dayClick(day) {
      this.$emit('day-click', day.id);
    },
    monthUpdate(data) {
      this.month = `${data.year}-${data.month}`;
      this.$emit('month-click', this.month);
    },
    todayClick() {
      const calendar = this.$refs.calendar;
      calendar.focusDate(new Date());
      this.$emit('day-click', this.today);
    },
    getHighlightClass(attributes) {
      if (!attributes) return;
      const highlightClasses = [];
      for (const attr of attributes) {
        if (attr?.highlight?.base?.contentClass)
          highlightClasses.push(attr.highlight.base.contentClass);
      }
      if (this.$device.isMobile) highlightClasses.push('is-mobile');
      return highlightClasses;
    },
    onClickClear() {
      this.$emit('clear-history');
    },
    search() {
      this.$emit('search');
    },
    close() {
      this.$emit('close');
    },
    onClickHistory(keyword) {
      this.$emit('click-history', keyword);
    },
    onClickSearchInput() {
      this.$emit('click-input');
    },
  },
};
</script>

<style lang="scss" scoped>
.calendar {
  width: 100%;
  padding: 0 16px;
  /deep/ .custom-calendar {
    width: 100%;
    &.vc-container {
      --weekday-bg: #dcdfe5;
      --day-border: 1px solid #dcdfe5;
      --day-width: 40px;
      --day-height: 40px;
      --day-line-height: 40px;
      --text-xl: 20px;
      border-radius: 0;
      border: none;
    }
    & .vc-header {
      padding: 16px 0;
      .vc-title {
        color: $font-normal;
        line-height: 30px;
        font-weight: 700;
      }
    }
    .vc-arrows-container {
      padding: 16px 0;
      width: 224px;
      left: calc(50% - 114px);

      .vc-arrow:hover {
        background: transparent;
      }
      .vc-arrow {
        color: $font-grayscale-2;
      }
    }
    & .vc-weeks {
      padding: 0;
    }
    & .vc-weekday {
      color: $font-grayscale-3;
      font-size: var(--text-base);
      padding: 0;
      line-height: 30px;
      margin-bottom: 8px;
    }
    & .vc-day-content {
      font-size: var(--text-base);
      line-height: var(--day-line-height);
      height: var(--day-height);
      width: var(--day-width);
      font-weight: var(--font-normal);
      border-radius: 50%;
      position: relative;
      border: 2px solid transparent;
      .day-label {
        border-radius: 50%;
      }

      .day-sticker {
        margin: 0;
        position: absolute;
      }
      .day-dot {
        width: 6px;
        height: 6px;
        background: $primary;
        border-radius: 50%;
        position: absolute;
        bottom: 3px;
      }
      &:hover {
        background: #fff0eb;
      }
      &.before-day {
        background: $bgcontainer;
        &:hover {
          background: $bgcontainer;
        }
      }
      &.today {
        border: 2px solid $secondary;
      }
      &.active {
        border: 2px solid $primary;
      }
    }
    & .vc-day {
      margin-bottom: 10px;
    }
  }
  .icon-Calendar {
    color: $primary;
    font-size: 32px;
    right: 16px;
    top: 16px;
    cursor: pointer;
    &.is-disable {
      color: $font-grayscale-3;
      cursor: initial;
    }
  }
}
</style>
