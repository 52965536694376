<template>
  <div class="live-card w-100 bg-white position-relative p-3 overflow-hidden">
    <div class="w-100">
      <div
        class="header d-flex justify-content-between align-items-center text-14"
      >
        <p v-if="type" class="m-0">【{{ type }}】</p>
        <p class="m-0">
          <span v-if="status === 'live'" class="d-flex align-items-center">
            <div class="live-indicator mr-2">
              <div class="red-dot"></div>
              <div class="pulse one"></div>
              <div class="pulse two"></div>
            </div>
            直播中
          </span>
          <span v-else-if="status === 'incoming'">即將開始</span>
        </p>
      </div>
      <div>
        <p
          v-for="(name, index) in names"
          :key="name"
          class="title font-weight-bold text-18 mb-0"
          :class="{
            'text-primary': index == names.length - 1 && names.length > 1,
          }"
        >
          {{ name }}
        </p>

        <div v-if="showTags" class="hashtags d-flex flex-wrap text-14">
          <span
            v-for="tag in filterTags"
            :key="tag"
            class="mr-1 mt-2 rounded-sm"
            :class="{'high-light': tag.indexOf('班') !== -1}"
            >{{ tag }}</span
          >
        </div>
      </div>
    </div>

    <div class="divider"></div>

    <div class="w-100 d-flex align-items-center justify-content-between">
      <div>
        <p class="info-text text-14 d-flex align-items-center">
          <i class="icon-Clock text-20 mr-2"></i>{{ date }}
        </p>
        <p
          v-if="['over', 'record'].includes(status)"
          class="warning-text text-14 d-flex align-items-center"
        >
          <i class="icon-Clock text-20 mr-2"></i>
          {{ removeDate }}
        </p>
        <p
          v-if="meetingIdText"
          class="info-text text-14 cursor-pointer d-flex align-items-center"
          @click="copy"
        >
          <i class="icon-Copy text-primary text-20 mr-2"></i>
          {{ meetingIdText }}
        </p>
      </div>

      <div class="bun-wrapper">
        <b-button
          v-if="['notStart'].includes(status) && showSubscription"
          :variant="liveCourse.isSubscription ? 'outline-primary' : 'primary'"
          size="sm"
          class="notification-btn d-flex align-items-center"
          :class="{
            'is-loading': liveCourse.isLoading,
            'is-subscription': liveCourse.isSubscription,
          }"
          @click="toggleNotification"
        >
          <b-spinner></b-spinner>
          <i class="icon-Check text-16 mr-2"></i>
          <i class="icon-Bell text-16 mr-2"></i>
          {{ !liveCourse.isSubscription ? $t('通知我') : $t('已設通知') }}
        </b-button>
        <b-button
          v-else-if="['live', 'incoming'].includes(status) && meetingIdText"
          variant="primary"
          size="sm"
          style="padding: 6px 14px"
          @click="enterLiveRoom"
        >
          {{ $t('進入直播') }}
        </b-button>
        <b-button
          v-else-if="liveCourse.isCompleted"
          variant="primary"
          size="sm"
          style="padding: 6px 14px"
          class="d-flex align-items-center"
          @click="watchRecord"
        >
          <i class="icon-Circle-play mr-1 text-16"></i> {{ $t('重播') }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import plansMixin from '@/mixin/plans';

export default {
  mixins: [plansMixin],
  props: {
    liveCourse: {
      type: Object,
      default() {
        return {};
      },
    },
    showTags: {
      type: Boolean,
      default: true,
    },
    showSubscription: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      NumsEnum: Object.freeze({
        0: '日',
        1: '一',
        2: '二',
        3: '三',
        4: '四',
        5: '五',
        6: '六',
      }),
      types: [
        '即時對戰解析',
        '即時對戰思維',
        '技術專題',
        '棋譜解說',
        '一棋一會',
        '對戰實況',
      ],
    };
  },
  inject: ['appNow'],
  computed: {
    now() {
      const now = this.appNow();
      return now;
    },
    status() {
      if (
        this.now >= this.liveCourse.startAt - 5 * 60 * 1000 &&
        this.now <= this.liveCourse.startAt
      ) {
        return 'incoming';
      } else if (this.now < this.liveCourse.startAt) {
        return 'notStart';
      } else if (this.now < this.liveCourse.endAt) {
        return 'live';
      } else if (this.liveCourse.isCompleted) {
        return 'record';
      } else {
        return 'over';
      }
    },
    date() {
      const date = this.$day(this.liveCourse.startAt).format('M/D');
      const day = this.NumsEnum[this.$day(this.liveCourse.startAt).day()];
      const start = this.$day(this.liveCourse.startAt).format('HH:mm');
      const end = this.$day(this.liveCourse.endAt).format('HH:mm');

      return `${date} (${day}) ${start}-${end}`;
    },
    removeDate() {
      const removeDate = this.$day(this.liveCourse.endAt)
        .add(90, 'day')
        .endOf('day');
      const date = this.$day(removeDate).format('M/D');
      const day = this.NumsEnum[this.$day(removeDate).day()];
      const time = this.$day(removeDate).format('HH:mm');

      return `${this.$t('於')} ${date} (${day}) ${time} ${this.$t('下架')}`;
    },
    names() {
      return this.liveCourse.name.split('\n');
    },
    meetingIdText() {
      let text = '';
      const str = String(this.liveCourse.meetingId);
      if (
        ['live', 'incoming'].includes(this.status) &&
        this.liveCourse.meetingId
      ) {
        text = `${this.$t('房間')} ID ${str.slice(0, 3)} ${str.slice(
          3,
          6
        )} ${str.slice(6)}`;
      }
      return text;
    },
    filterTags() {
      return this.liveCourse.tags.filter((tag) => {
        return !this.types.includes(tag);
      });
    },
    type() {
      return this.liveCourse.tags.find((tag) => this.types.includes(tag));
    },
  },
  methods: {
    copy() {
      this.$emit('copy', this.liveCourse.meetingId);
    },
    enterLiveRoom() {
      this.$emit('enterLiveRoom', this.liveCourse);
    },
    async watchRecord() {
      this.$emit('watchRecord', this.liveCourse);
    },
    toggleNotification() {
      if (this.liveCourse.isLoading) {
        return;
      }
      if (['notStart'].includes(this.status)) {
        this.$emit('toggleNotification', this.liveCourse);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.live-card {
  box-shadow: $card-box-shadow;
  border-radius: $card-border-radius;
  padding: 14px 24px;
  &:not(:last-child) {
    margin-bottom: 16px;
  }

  .header {
    color: $font-grayscale-1;
    line-height: 22px;
    margin-left: -8px;
  }
  .live-indicator {
    width: 10px;
    height: 10px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .live-indicator .red-dot,
  .live-indicator .pulse {
    position: absolute;
    height: 10px;
    width: 10px;
    border-radius: 18px;
    background-color: #ff412b;
  }

  .live-indicator .pulse {
    animation-name: red-dot-scale-fade;
    animation-iteration-count: infinite;
    animation-duration: 2s;
  }

  .live-indicator .pulse.two {
    animation-delay: 0.25s;
  }

  .divider {
    background: $line-2;
    height: 1px;
    margin: 12px 0;
  }
  .hashtags {
    color: $font-grayscale-2;
    line-height: 20px;
    span {
      border: 1px solid $line-2;
      padding: 2px 8px;
      &.high-light {
        color: $white;
        background: $primary;
        border-color: $primary;
      }
    }
  }
  .info-text {
    color: $font-grayscale-2;
    margin: 0;
  }
  .warning-text {
    color: $font-third;
    margin: 0;
  }
  .title {
    line-height: 34px;
  }
  .info-text:not(:last-child),
  .warning-text:not(:last-child) {
    margin-bottom: 8px;
  }

  .spinner-border {
    width: 16px;
    height: 16px;
    border-width: 2px;
    position: absolute;
    transition: all 0.3s;
    bottom: -35px;
    left: 14px;
  }
  .icon-Check {
    position: absolute;
    bottom: -35px;
    left: 14px;
    transition: all 0.3s;
  }
  .icon-Bell {
    position: absolute;
    bottom: 8px;
    left: 14px;
    transition: all 0.3s;
  }
  .notification-btn {
    overflow: hidden;
    position: relative;
    padding: 6px 14px 6px 34px;
    &:hover {
      .icon-Check {
        bottom: 8px;
      }
      .icon-Bell {
        bottom: 35px;
      }
    }
    &.is-subscription {
      .icon-Check {
        bottom: 8px;
      }
      .icon-Bell {
        bottom: 35px;
      }
      .spinner-border {
        bottom: 35px;
      }
    }
    &.is-loading {
      .icon-Check {
        bottom: 35px;
      }
      .icon-Bell {
        bottom: 35px;
      }
      .spinner-border {
        bottom: 8px;
      }
    }
  }
  button {
    border-radius: 100px;
    transition: all 0.3s;
  }
}

@keyframes red-dot-scale-fade {
  0% {
    opacity: 0.5;
    transform: scale(1);
  }
  50% {
    opacity: 0;
    transform: scale(2.5);
  }

  100% {
    opacity: 0;
    transform: scale(2.5);
  }
}
</style>
