<template>
  <div class="filter-board-mobile position-relative">
    <div class="filter-list d-flex vw-100 bg-white">
      <div
        v-for="filter in filters"
        :key="filter.key"
        class="filter w-50 d-flex align-items-center justify-content-between cursor-pointer"
        :class="{
          disabled: expiredDay <= 0,
          'is-selecting': filter.key === currentSelecting,
        }"
        @click="onClickFilter(filter.key)"
      >
        <span
          class="text-truncate mr-2"
          :class="{
            'text-primary': ![
              $t('所有直播'),
              $t('兒童－全部'),
              $t('不分齡－全部'),
              '',
            ].includes(sheets[filter.key]),
          }"
          >{{ sheets[filter.key] }}</span
        >
        <i class="icon-Chevron-down text-24"> </i>
      </div>
    </div>

    <div
      v-if="isDropdownShow"
      class="dropdown-wrapper position-absolute w-100 overflow-hidden"
      :class="{show: isDropdownShow}"
      @click="closeSheet"
    >
      <div v-if="['times'].includes(currentSelecting)" class="dropdown">
        <div
          v-for="tag in currentSheetTags"
          :key="tag"
          class="sheet-option d-flex align-items-center justify-content-between position-relative bg-white"
          @click.stop="onClickTag(tag)"
        >
          {{ tag }}
          <i
            v-show="sheets[currentSelecting] == tag"
            class="icon-Check text-32 text-primary"
          ></i>
        </div>
      </div>
      <div
        v-else
        class="dropdown h-100 bg-bgcontainer scroll-bar"
        style="overflow: overlay"
        @click.stop
      >
        <div
          v-for="(planType, index) in planTypesDropdown"
          :key="planType.planType"
          @click.stop="toggleShow(index)"
        >
          <div
            class="sheet-option d-flex align-items-center justify-content-between position-relative bg-white"
          >
            <span class="font-weight-bold">{{ planType.planType }}</span>
            <i
              class="text-32 text-primary"
              :class="planType.show ? 'icon-Circle-minus' : 'icon-Circle-plus'"
            ></i>
          </div>
          <div v-show="planType.show">
            <div
              v-for="plan in planType.plans"
              :key="plan"
              class="sheet-option d-flex align-items-center justify-content-between position-relative"
              @click="onClickTag(plan, planType.planType)"
            >
              {{ plan }}
              <i
                v-show="
                  sheets.planType === planType.planType &&
                  sheets[currentSelecting] == plan
                "
                class="icon-Check text-32 text-primary"
              ></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BackEvent from '@/lib/base/backEvent.js';
import _ from 'lodash';

export default {
  props: {
    planTypesArr: {
      type: Array,
      default() {
        return [];
      },
    },
    times: {
      type: Array,
      default() {
        return [];
      },
    },
    sheets: {
      type: Object,
      default() {
        return {};
      },
    },
    isPageLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentSelecting: '',
      isDropdownShow: false,
      planTypesDropdown: [],
    };
  },
  computed: {
    currentPlan() {
      return this.$store.getters['course/currentPlan'];
    },
    expiredDay() {
      return this.$store.getters['user/liveCourseExpiredDay'];
    },
    isKidType() {
      return this.currentPlan?.id?.substring(0, 3) === 'kid';
    },
    screenHeight() {
      return this.$store.state.env.height;
    },
    sheetHeight() {
      let sheetHeight = 50 * this.currentSheetTags.length + 56;

      if (this.screenHeight - sheetHeight < 100) {
        sheetHeight = this.screenHeight - 100;
      }
      return sheetHeight + 'px';
    },
    currentSheetTags() {
      return this[this.currentSelecting] || [];
    },
    filters() {
      return [
        {
          key: 'plans',
          text: this.$t('程度'),
        },

        {
          key: 'times',
          text: this.$t('時間'),
        },
      ];
    },
  },
  methods: {
    onClickTag(name, planType) {
      const newSheets = _.cloneDeep(this.sheets);

      if (newSheets.planType !== planType && planType) {
        newSheets.planType = planType;
      }
      newSheets[this.currentSelecting] = name;
      this.$emit('change', newSheets);
      this.closeSheet();
    },
    closeSheet() {
      this.isDropdownShow = false;
      this.currentSelecting = '';
    },
    onClickFilter(value) {
      if (this.isPageLoading) {
        return;
      }
      if (this.isDropdownShow && this.currentSelecting === value) {
        return this.closeSheet();
      }
      BackEvent.addEvent(() => {
        this.closeSheet();
      });
      this.planTypesDropdown = _.cloneDeep(this.planTypesArr);
      this.isDropdownShow = true;
      this.currentSelecting = value;
    },
    toggleShow(typeIndex) {
      this.planTypesDropdown.forEach((planType, index) => {
        planType.show = typeIndex === index;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-list {
  box-shadow: 0px 2px 7px rgba(199, 199, 199, 0.3);
  .filter {
    padding: 10px 12px;
    line-height: 28px;
    transition: all 0.3s;
    .icon-Chevron-down {
      transition: all 0.3s;
    }
    &:hover {
      .icon-Chevron-down {
        color: $primary;
      }
    }
    &.disabled {
      color: #ced4da;
      .icon-Chevron-down {
        color: #ced4da;
      }
    }
    &.is-selecting {
      color: $primary;
      .icon-Chevron-down {
        transform: rotate(180deg);
      }
    }
  }
}

.dropdown {
  border-top: 1px solid $line-2;
}
.sheet-option {
  height: 50px;
  padding: 0 16px;
  border-bottom: 1px solid $line-2;
}
.dropdown-wrapper {
  background: rgba(0, 0, 0, 0.5);
  height: calc(
    100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom) - 56px - 62px -
      48px
  );
  // top: 100%;
  z-index: 1000;
  transition: all 0.3s;
  display: none;
  &.show {
    display: block;
  }
}
</style>
